import { ComponentRef } from '@angular/core'
import { debounceTime } from 'rxjs'

import { FilterConfig } from '@goplanner/form-builder'
import { ColumnConfig, FieldConfig } from '@goplanner/api-client'
import { ListPageModuleData, timePeriodField } from '@goplanner/module-builder'

import { ConfirmationUpdateComponent } from 'src/app/shared/components/confirmation-update/confirmation-update.component'
import { timeRenderer } from 'src/app/shared/classes/renderers'

export const fieldConfigDireccionesEntrega: FieldConfig<'cli_direccion_entrega'> =
  {
    id_sucursal: {
      expressions: {
        'props.disabled': '!model.id_cliente',
        'model.id_sucursal': 'model.id_cliente ? model.id_sucursal : null'
      },
      filterBy: ['id_cliente', 'id_cliente']
    }
  }

export const columnConfigDireccionesEntrega: ColumnConfig<'cli_direccion_entrega'> =
  {
    cliente__nombre_calculado: {
      renderer: (record) => {
        if (!record.cliente?.nombre_calculado) return '---'
        return `<div class="flex gap-1 border-round border-1 border-200 py-1 px-2">
                  <i class="${record.cliente?.tipo_cliente?.icono} mr-2"></i>
                  <i class="fi fi-${record.cliente?.pais?.pais_siglas.toLowerCase()} mr-2"></i>
                  <div class="text-uppercase overflow-hidden text-overflow-ellipsis">${
                    (record.cliente?.nombre_calculado as string) ?? ''
                  }</div>
                </div>`
      }
    },
    direccion__nombre: {
      renderer: (record) => {
        return `${
          record.direccion?.pais?.pais_siglas
            ? `<i class="fi fi-${record.direccion.pais.pais_siglas.toLowerCase()} mr-2"></i>`
            : '<i class="fa-solid fa-location-dot mr-2"></i>'
        }${record.direccion?.nombre ?? '---'}`
      }
    }
  }

export const fieldConfigDirecciones: FieldConfig<'ent_direcciones'> = {
  alias_direccion: {
    cols: {
      md: 6
    }
  },
  nombre: {
    cols: {
      md: 5
    }
  },
  numero: {
    cols: {
      md: 2,
      lg: 2
    }
  },
  coordenadas: {
    cols: {
      md: 6
    }
  },
  id_comunidad: {
    filterBy: ['id_pais', 'id_pais']
  },
  id_provincia: {
    filterBy: [
      ['id_pais', 'id_pais'],
      ['id_comunidad', 'id_comunidad']
    ]
  },
  id_poblacion: {
    cols: {
      md: 12,
      lg: 5
    },
    filterBy: [
      ['id_pais', 'id_pais'],
      ['id_comunidad', 'id_comunidad'],
      ['id_provincia', 'id_provincia']
    ]
  },
  id_codigo_postal: {
    filterBy: [
      ['id_pais', 'id_pais'],
      ['id_comunidad', 'id_comunidad'],
      ['id_provincia', 'id_provincia'],
      ['id_poblacion', 'id_poblacion']
    ]
  },
  id_cliente: {
    cols: {
      md: 12,
      lg: 12
    }
  },
  codigo_erp: {
    cols: {
      md: 12,
      lg: 4
    }
  },
  tiempo_carga_medio: {
    type: 'dropdown',
    props: timePeriodField,
    cols: {
      md: 12,
      lg: 4
    }
  },
  tiempo_descarga_medio: {
    type: 'dropdown',
    props: timePeriodField,
    cols: {
      md: 12,
      lg: 4
    }
  }
}

export const columnConfigDirecciones: ColumnConfig<'ent_direcciones'> = {
  cliente__nombre_calculado: {
    renderer: (record) => {
      if (!record.cliente?.nombre_calculado) return '---'
      return `<div class="flex gap-1 border-round border-1 border-200 py-1 px-2 ">
                <i class="fi fi-${record.cliente?.pais?.pais_siglas.toLowerCase()} mr-2"></i>
                <div class="text-uppercase overflow-hidden text-overflow-ellipsis">${
                  (record.cliente?.nombre_calculado as string) || ''
                }</div>
              </div>`
    }
  },
  pais__nombre: {
    header: 'País',
    headerStyle: {
      width: '5rem'
    },
    renderer: (record) => {
      let widgethtml = ''
      if (record.id_pais) {
        widgethtml = `<div class="flex gap-1"><i class="fi fi-${record.pais?.pais_siglas.toLocaleLowerCase()}"></i> ${
          record.pais?.nombre
        }</div>`
      }
      return widgethtml
    }
  },
  coordenadas: {
    renderer: (record) => {
      let widgethtml = ''
      const coordenadas = record.coordenadas as unknown as {
        type: string
        coordinates: [number, number]
      }

      if (record.coordenadas) {
        widgethtml = `<div class="select-all border-round border-1 border-400 py-1 px-2"> ${coordenadas.coordinates[1]},${coordenadas.coordinates[0]} </div>`
      }
      return widgethtml
    }
  },
  tiempo_carga_medio: {
    renderer: (record) => timeRenderer(record.tiempo_carga_medio),
    exportRenderer: (record) => timeRenderer(record.tiempo_carga_medio)
  },
  tiempo_descarga_medio: {
    renderer: (record) => timeRenderer(record.tiempo_descarga_medio),
    exportRenderer: (record) => timeRenderer(record.tiempo_descarga_medio)
  }
}

export const filterConfigDirecciones: FilterConfig<'ent_direcciones'> = [
  {
    field: 'id_pais',
    baseFilter: [
      {
        field: 'asignado_expediente',
        operator: '=',
        value: true
      }
    ]
  },
  {
    field: 'id_comunidad',
    baseFilter: [
      {
        field: 'asignado_expediente',
        operator: '=',
        value: true
      }
    ]
  },
  {
    field: 'id_provincia',
    baseFilter: [
      {
        field: 'asignado_expediente',
        operator: '=',
        value: true
      }
    ]
  },
  {
    field: 'id_poblacion',
    baseFilter: [
      {
        field: 'asignado_expediente',
        operator: '=',
        value: true
      }
    ]
  }
]

let confirmationUpdate:
  | ComponentRef<ConfirmationUpdateComponent<'ent_direcciones'>>
  | undefined

export const updateAddressAction: ListPageModuleData<'ent_direcciones'>['actions'] =
  [
    {
      tooltip: (data) =>
        data.activo ? 'Desactivar dirección' : 'Activar dirección',
      icon: (data) => (data.activo ? 'fa-solid fa-times' : 'fa-solid fa-check'),
      color: (data) => (data.activo ? 'p-button-danger' : 'p-button-success'),
      handler(record, _, vcr, cmp) {
        if (confirmationUpdate) return
        const confirmMessage = record.activo
          ? '¿Desea desactivar la dirección?'
          : '¿Desea activar la dirección?'

        confirmationUpdate = vcr.createComponent(
          ConfirmationUpdateComponent<'ent_direcciones'>
        )

        confirmationUpdate.instance.id = record.id
        confirmationUpdate.instance.entityName = 'ent_direcciones'
        confirmationUpdate.instance.values = {
          activo: !record.activo
        }
        confirmationUpdate.instance.confirmMessage = confirmMessage
        confirmationUpdate.instance.type = record.activo ? 'danger' : 'success'

        const afterUpdate = confirmationUpdate.instance.afterUpdate.subscribe(
          () => cmp.reload()
        )

        const destroy = confirmationUpdate.instance.destroy
          .pipe(debounceTime(100))
          .subscribe(() => {
            confirmationUpdate?.destroy()
            confirmationUpdate = undefined
            afterUpdate?.unsubscribe()
            destroy?.unsubscribe()
          })
      }
    }
  ]
